// Assuming you're working with a React environment and using Bootstrap for styling, here's a possible implementation:

import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { Col, Container, Row, Modal, Form, ToggleButton } from 'react-bootstrap';
import { getNewTrainingDetail, getNewTrainingList, setNewTraining, updateNewTraining } from '../../lib/RestApi';
import { useNavigate } from 'react-router-dom';


const NewTrainingTrainerScheduleView = () => {
	const navigate = useNavigate()
	const [trainingData, setTrainingData] = useState({});
	const [allCount, setAllCount] = useState(0);
	const [pageData, setPageData] = useState(1);
	const [rowData, setRowData] = useState(7);
	const [pagenationArr, setPagenationArr] = useState([]);
	const [locations, setLocations] = useState([{ name: '', address: '', timeSlots: [{ time: '', to: '' }] }]);
	const [showModal, setShowModal] = useState(false);
	const [showModalModify, setShowModalModify] = useState(false);
	const [updateFlag, setUpdateFlag] = useState(0);
	const [locationsModify, setlocationsModify] = useState([{ TrainingDate: '', TrainingVenueName: '', TrainingVenueAddress: '', timeSlots: [{ CapacitiesLimitCount: 0, CapacitiesRealIsActive: false, TrainingVenueCapacitiesId: 0, TrainingVenueTime: '' }] }]);
	

	useEffect(() => {
		const getData = async () => {
			try {
				const result = await getNewTrainingList(pageData, rowData)
				if (result.ResultCode == 200) {
					setTrainingData(result.ResultData.List)
					setAllCount(result.ResultData.Count)
					const tmpArr = []
					for (let i = 0; i < Math.ceil(result.ResultData.Count / rowData); i++) {
						tmpArr.push(i + 1)
					}

					setPagenationArr(tmpArr)
				}
			}
			catch (e) {

			}
		}
		getData();
	}, [pageData, updateFlag]);

	const handleClose = () => setShowModal(false);
	const handleShow = () => setShowModal(true);

	const handleCloseModify = () => setShowModalModify(false);
	const handleShowModify = () => setShowModalModify(true);

	const addLocation = () => {
		setLocations([...locations, { name: '', address: '', timeSlots: [{ time: '', to: '' }] }]);
	};

	const removeLocation = (index) => {
		const newLocations = locations.filter((_, i) => i !== index);
		setLocations(newLocations);
	};

	const addTimeSlot = (locationIndex) => {
		const newLocations = [...locations];
		newLocations[locationIndex].timeSlots.push({ time: '', to: '' });
		setLocations(newLocations);
	};

	const removeTimeSlot = (locationIndex, timeSlotIndex) => {
		const newLocations = [...locations];
		newLocations[locationIndex].timeSlots = newLocations[locationIndex].timeSlots.filter((_, i) => i !== timeSlotIndex);
		setLocations(newLocations);
	};

	const handleLocationChange = (index, field, value) => {
		const newLocations = [...locations];
		newLocations[index][field] = value;
		setLocations(newLocations);
	};

	const handleTimeSlotChange = (locationIndex, timeSlotIndex, field, value) => {
		const newLocations = [...locations];
		newLocations[locationIndex].timeSlots[timeSlotIndex][field] = value;
		setLocations(newLocations);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log(locations);
		console.log(e.target[0].value);
		console.log(e.target[1].value);
		console.log('Form submitted');

		const result = await setNewTraining(e.target[0].value, e.target[1].value, locations)
		if (result.ResultCode == 200) {
			handleClose()
			setUpdateFlag(updateFlag + 1)

		}

	};

	const handleSubmit2 = async (e) => {
		e.preventDefault()
		const result = await updateNewTraining(locationsModify)
		if (result.ResultCode == 200) {
			navigate(0)
		}
	};

	// 지역할증 
	// 

	const getDetail = async (DateYmd) => {
		const result = await getNewTrainingDetail(DateYmd)
		setlocationsModify(result.ResultData)
		handleShowModify()
	}

	return (
		<Container>
			<Button variant="primary" onClick={handleShow}>
				교육 등록
			</Button>
			<Table bordered className="training-schedule-table">
				<thead>
					<tr>
						<th>날짜</th>
						<th>교육장</th>
						<th>교육시간</th>
						<th>정원</th>
						<th>지원자</th>
						<th>시간별 상태</th>
						<th>버튼</th>
					</tr>
				</thead>
				<tbody>
					{Object.entries(trainingData).map(([date, venues], dateIndex) => (
						<React.Fragment key={dateIndex}>
							{Object.entries(venues).map(([venueName, sessions], venueIndex) => (
								sessions && Array.isArray(sessions) && sessions.map((session, sessionIndex) => (
									<tr key={`${venueName}-${sessionIndex}`} className={sessionIndex === 0 && venueIndex === 0 ? 'date-separator' : ''}>
										{venueIndex === 0 && sessionIndex === 0 && (
											<td rowSpan={Object.values(venues).flat().length} className="date-cell">{date}</td>
										)}
										{sessionIndex === 0 && (
											<td rowSpan={sessions.length}>{venueName}</td>
										)}
										<td>{session.TrainingVenueTime}</td>
										<td>{session.CapacitiesLimitCount}</td>
										<td>{session.ReqCount}</td>
										<td style={{fontWeight: (session.CapacitiesIsActive == 0) ? 'bold' : undefined}}>{session.CapacitiesIsActive == 0 ? '닫힘' : '열림'} </td>
										{venueIndex === 0 && sessionIndex === 0 && (
											<td rowSpan={Object.values(venues).flat().length} className="button-cell">
												<Button variant="primary" size="sm" onClick={() => getDetail(date)}>수정</Button>{' '}
												{/* <Button variant="danger" size="sm">삭제</Button> */}
											</td>
										)}
									</tr>
								))
							))}
						</React.Fragment>
					))}
				</tbody>
			</Table>

			<Row className='text-center'>
				<Col>
					<ul class="pagination">
						{pagenationArr.map((data, idx) => (
							<li key={idx} class={`page-item ${pageData === data ? 'active' : ''}`}><a class="page-link" onClick={() => setPageData(data)}>{data}</a></li>
						))}
					</ul>
				</Col>
			</Row>

			<Modal show={showModal} onHide={handleClose} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>교육장 등록</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleSubmit}>
						<Row className="mb-3">
							<Col>
								<Form.Group controlId="startDate">
									<Form.Label>시작일시</Form.Label>
									<Form.Control type="text" required placeholder='2024-10-11' />
								</Form.Group>
							</Col>
							<Col>
								<Form.Group controlId="endDate">
									<Form.Label>종료일시</Form.Label>
									<Form.Control type="text" required placeholder='2024-10-11' />
								</Form.Group>
							</Col>
						</Row>

						{locations.map((location, locationIndex) => (
							<div key={locationIndex}>
								<Row className="mb-3">
									<Col>
										<Form.Control
											type="text"
											placeholder="교육장 이름"
											value={location.name}
											onChange={(e) => handleLocationChange(locationIndex, 'name', e.target.value)}
											required
										/>
									</Col>
									<Col>
										<Form.Control
											type="text"
											placeholder="교육장 주소"
											value={location.address}
											onChange={(e) => handleLocationChange(locationIndex, 'address', e.target.value)}
											required
										/>
									</Col>
									<Col xs="auto">
										<Button variant="danger" onClick={() => removeLocation(locationIndex)}>
											삭제
										</Button>
									</Col>
								</Row>

								{location.timeSlots.map((timeSlot, timeSlotIndex) => (
									<Row key={timeSlotIndex} className="mb-3">
										<Col>
											<Form.Control
												type="text"
												placeholder="교육장 시간 (10:00)"
												value={timeSlot.time}
												onChange={(e) => handleTimeSlotChange(locationIndex, timeSlotIndex, 'time', e.target.value)}
												required
											/>
										</Col>
										<Col>
											<Form.Control
												type="number"
												placeholder="시간별 TO"
												value={timeSlot.to}
												onChange={(e) => handleTimeSlotChange(locationIndex, timeSlotIndex, 'to', e.target.value)}
												required
											/>
										</Col>
										<Col xs="auto">
											<Button variant="danger" onClick={() => removeTimeSlot(locationIndex, timeSlotIndex)}>
												삭제
											</Button>
										</Col>
									</Row>
								))}

								<Button variant="secondary" onClick={() => addTimeSlot(locationIndex)} className="mb-3">
									시간 추가
								</Button>
							</div>
						))}

						<Button variant="secondary" onClick={addLocation} className="mb-3">
							교육장 추가
						</Button>

						<Button variant="primary" type="submit">
							등록
						</Button>
					</Form>
				</Modal.Body>
			</Modal>


			<Modal show={showModalModify} onHide={handleCloseModify} size="lg">
				<Modal.Header closeButton>
					<Modal.Title>교육장 수정 ({locationsModify[0].TrainingDate})</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleSubmit2}>
						<Row className="mb-3">
							<Col>
								{locationsModify[0].TrainingDate}
							</Col>
							<Col>
								<Form.Check
									type="switch"
									id="switch-date"
									label={locationsModify[0].TrainingDateRealIsActive ? "날짜 On" : "날짜 Off"}
									checked={locationsModify[0].TrainingDateRealIsActive}
									onChange={() => {
										const updateData = locationsModify.map((item, idx) => {
											return {
												...item,
												TrainingDateRealIsActive: !item.TrainingDateRealIsActive,
												VenueRealIsActive: !item.TrainingDateRealIsActive,
												timeSlots: item.timeSlots.map((slot, slotIndex) => {
													return {
														...slot,
														CapacitiesRealIsActive: !item.TrainingDateRealIsActive
													};
												})
											}
										})
										setlocationsModify(updateData)
									}}
								/>
							</Col>

						</Row>
						{locationsModify.map((location, locationIndex) => (
							<div key={locationIndex}>
								<Row className="mb-3">
									<Col>
										<Form.Control
											type="text"
											placeholder="교육장 이름"
											value={location.TrainingVenueName}
											required
											disabled
										/>
									</Col>
									<Col>
										<Form.Control
											type="text"
											placeholder="교육장 주소"
											value={location.TrainingVenueAddress}
											required
											disabled
										/>
									</Col>
									<Col style={{ alignSelf: 'center' }}>
										<Form.Check
											type="switch"
											id={`switch-venue-${locationIndex}`}
											label={location.VenueRealIsActive ? "교육장 On" : "교육장 Off"}
											checked={location.VenueRealIsActive}
											onChange={() => {
												const updateData = locationsModify.map((item, idx) => {
													if (idx === locationIndex) {
														return {
															...item,
															VenueRealIsActive: !item.VenueRealIsActive,
															timeSlots: item.timeSlots.map((slot, slotIndex) => {
																return {
																	...slot,
																	CapacitiesRealIsActive: !item.VenueRealIsActive
																};
															})
														}
													}
													return item;
												})
												setlocationsModify(updateData)
											}}
										/>
									</Col>
								</Row>
								<hr />

								{location.timeSlots.map((timeSlot, timeSlotIndex) => (
									<Row key={timeSlotIndex} className="mb-3">
										<Col>
											<Form.Control
												type="text"
												placeholder="교육장 시간 (10:00)"
												value={timeSlot.TrainingVenueTime}
												required
												disabled
											/>
										</Col>
										<Col>
											<Form.Control
												type="number"
												placeholder="시간별 TO"
												value={timeSlot.CapacitiesLimitCount}
												onChange={(e) => {
													const updateData = locationsModify.map((item, idx) => {
														if (idx === locationIndex) {
															return {
																...item,
																timeSlots: item.timeSlots.map((slot, slotIndex) => {
																	if (slotIndex === timeSlotIndex) {
																		return {
																			...slot,
																			CapacitiesLimitCount: Number(e.target.value)
																		};
																	}
																	return slot;
																})
															}
														}
														return item;
													})
													setlocationsModify(updateData)
												}}
												required

											/>
										</Col>
										<Col style={{ alignSelf: 'center' }}>


											<Form.Check
												type="switch"
												id={`switch-time-${timeSlot.TrainingVenueCapacitiesId}`}
												label={timeSlot.CapacitiesRealIsActive ? "시간 On" : "시간 Off"}
												checked={timeSlot.CapacitiesRealIsActive}
												onChange={() => {
													const updateData = locationsModify.map((item, idx) => {
														if (idx === locationIndex) {
															return {
																...item,
																timeSlots: item.timeSlots.map((slot, slotIndex) => {
																	if (slotIndex === timeSlotIndex) {
																		return {
																			...slot,
																			CapacitiesRealIsActive: !slot.CapacitiesRealIsActive
																		};
																	}
																	return slot;
																})
															}
														}
														return item;
													})
													setlocationsModify(updateData)
												}}
											/>
										</Col>

									</Row>
								))}
								<hr /><hr />

								
							</div>
						))}

						<Button variant="primary" type="submit">
							수정
						</Button>
					</Form>
				</Modal.Body>
			</Modal>
		</Container>
	);
};

export default NewTrainingTrainerScheduleView;
