import React, { useState } from 'react';
import moment from 'moment';
import { extendMoment } from 'moment-range';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
.calendar {
  width: 300px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.body {
  display: flex;
  flex-direction: column;
}

.week {
  display: flex;
}

.day {
  flex: 1;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ddd;
  text-align: center;
}

.other-month {
  color: #ccc;
}

.days-header .day {
  font-weight: bold;
}

.data-available {
    background: #94f5c4;
    font-weight: bold;
}
`;

const momentRange = extendMoment(moment);

const Calendar = ({ data, onSelectedDate, onMonthDate }) => {
    const [currentMonth, setCurrentMonth] = useState(moment());

    const startOfMonth = currentMonth.clone().startOf('month');
    const endOfMonth = currentMonth.clone().endOf('month');
    const startOfFirstWeek = startOfMonth.clone().startOf('week');
    const endOfLastWeek = endOfMonth.clone().endOf('week');
    const monthRange = momentRange.range(startOfFirstWeek, endOfLastWeek);

    const weeks = Array.from(monthRange.by('week'));

    const renderDays = () => {
        return weeks.map(week => (
            <div className="week" key={week.toString()}>
                {
                    Array.from(momentRange.range(week.clone().startOf('week'), week.clone().endOf('week')).by('day')).map(day => (
                        <div
                            className={`day ${day.isSame(currentMonth, 'month') ? '' : 'other-month'} ${data.some(d => moment(d.TrainingReqDate).isSame(day, 'day')) && 'data-available'}`}
                            key={day.toString()}
                            onClick={()=>{ onSelectedDate(day.format('yyyy-MM-DD'))}}
                            >
                            {day.format('D')}
                        </div>
                    ))
                }
            </div>
        ));
    };

    const prevMonth = () => {
        setCurrentMonth(currentMonth.clone().subtract(1, 'month'));
        onMonthDate(currentMonth.clone().subtract(1, 'month').format('yyyy-MM'))
    };

    const nextMonth = () => {
        setCurrentMonth(currentMonth.clone().add(1, 'month'));
        onMonthDate(currentMonth.clone().add(1, 'month').format('yyyy-MM'))
    };

    return (
        <>
            <GlobalStyle />
            <div className="calendar">
                <div className="header">
                    <button onClick={prevMonth}>이전</button>
                    <h2>{currentMonth.format('YYYY년 MM월')}</h2>
                    <button onClick={nextMonth}>다음</button>
                </div>
                <div className="body">
                    <div className="week days-header">
                        {['일', '월', '화', '수', '목', '금', '토'].map(day => (
                            <div className="day" key={day}>{day}</div>
                        ))}
                    </div>
                    {renderDays()}
                </div>
            </div>
        </>
    );
};

export default Calendar;
