import React from 'react';
import { isNullOrUndefined } from '../lib/Util';

const TextWithNewlines = ({ text }) => {
    if (isNullOrUndefined(text.split('\n'))) {
        return (<></>)
    }
        
    return (
        <div>
            {text.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ))}
        </div>
    );
};

export default TextWithNewlines;
