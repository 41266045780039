import React, { useEffect, useState, useCallback } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
// import DatePicker from 'react-datepicker';
// import Calendar from '../../components/Calendar';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import { getRecruitDetail, getRecruitList, getTrainingRequestList, getTrainingRequestMonthList, updateTrainingPass } from '../../lib/RestApi';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TextWithNewlines from '../../components/TextWithNewLines';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';


const RecruitList = (props) => {
	const [selectedDate, setSelectedDate] = useState(moment().format('yyyy-MM-DD'))
	const [monthDate, setMonthDate] = useState(moment().format('yyyy-MM'))
	const [RecruitList, setRecruitList] = useState([])
	const [RecruitDetailInfo, setRecruitDetailInfo] = useState({
		"Id": 0,
		"Category": 0,
		"CarModelTop1": "",
		"AddressTop1": "",
		"AddressTop2": "",
		"ParkingStatus": "",
		"ProductTitle": "",
		"SilverPrice": 0,
		"GoldPrice": 0,
		"AmbassadorPrice": 0,
		"ProductDay": 0,
		"ProductHour": 0,
		"AllHour": 0,
		"SpecialNoteComment": "",
		"NomenName": "",
		"SmokingStatus": "",
		"MeetingPlace": "",
		"GetKey": "",
		"DrivingStyle": "",
		"HateInfo": "",
		"Title": "",
		"PaymentDetailComment": "",
		"AddressTop1Lat": "",
		"AddressTop1Lng": "",
		"AddressTop2Lat": "",
		"AddressTop2Lng": "",
		"RequestList": [
			{
				"AnnounceId": 0,
				"DriverNo": 0,
				"UserName": "",
				"PhoneNumber": "",
				"AnnounceStatus": 0,
				"RequestDt": ""
			}
		]
	})
	const [modalFlag, setModalFlag] = useState(false)
	const [modalFlagInsert, setModalFlagInsert] = useState(false)
	const { register, handleSubmit } = useForm();


	useEffect(() => {
		const getData = async () => {
			let result = await getRecruitList()
			console.log(result)
			if (result.ResultCode == 200) {
				setRecruitList(result.ResultData)
			}
			else {
				alert('데이터 취득 실패')
			}
		}
		getData();
	}, [])

	const showDetail = async (Id) => {
		try {
			let result = await getRecruitDetail(Id)
			if (result.ResultCode == 200) {
				setRecruitDetailInfo(result.ResultData)
				setModalFlag(true)
			}
			else {
				alert('데이터 취득 실패')
			}
		}
		catch (e) {

		}
	}

	const handleClose = () => {
		setModalFlag(false)
	}
	const handleClose2 = () => {
		setModalFlagInsert(false)
	}

	const onSubmit = (data) => {
		console.log(data);
		// 데이터 처리 로직을 추가하세요
	};


	return (
		<>
			<Container fluid="md">
				<h2>공고 리스트</h2>
				<Button onClick={() => setModalFlagInsert(true)}>신규추가</Button>
				<br />
				<Table striped bordered hover size="sm">
					<thead>
						<tr>
							<th>고유 Id</th>
							<th>제목</th>
							<th>월예상정산액</th>
							<th>주소</th>
							<th>차량모델</th>
							<th>주차가능여부</th>
							<th>지원자수</th>
						</tr>
					</thead>
					<tbody>
						{RecruitList?.map((data) => (
							<tr onClick={() => showDetail(data.Id)}>
								<td>{data.Id}</td>
								<td>{data.Title}</td>
								<td>{data.PaymentSummaryComment}</td>
								<td>{data.AddressTop1}</td>
								<td>{data.CarModelTop1}</td>
								<td>{data.ParkingStatus}</td>
								<td>{data.RequestCount}</td>
							</tr>
						))}
					</tbody>
				</Table>
			</Container>

			<Modal
				show={modalFlag}
				size="lg"
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>{RecruitDetailInfo.Title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col xs={3} className=''>자택주소</Col>
						<Col xs={9} className=''>{RecruitDetailInfo.AddressTop1}</Col>
					</Row>
					<Row>
						<Col xs={3} className=''>회사주소</Col>
						<Col xs={9} className=''>{RecruitDetailInfo.AddressTop2}</Col>
					</Row>
					<Row>
						<Col xs={3} className=''>주차지원여부</Col>
						<Col xs={9} className=''>{RecruitDetailInfo.ParkingStatus}</Col>
					</Row>
					<Row>
						<Col xs={3} className=''>고객의 호칭</Col>
						<Col xs={9} className=''>{RecruitDetailInfo.NomenName}</Col>
					</Row>
					<Row>
						<Col xs={3} className=''>흡연여부</Col>
						<Col xs={9} className=''>{RecruitDetailInfo.SmokingStatus}</Col>
					</Row>
					<Row>
						<Col xs={3} className=''>만나는 장소</Col>
						<Col xs={9} className=''>{RecruitDetailInfo.MeetingPlace}</Col>
					</Row>
					<Row>
						<Col xs={3} className=''>키 수령 방법</Col>
						<Col xs={9} className=''>{RecruitDetailInfo.GetKey}</Col>
					</Row>
					<Row>
						<Col xs={3} className=''>선호 운행스타일</Col>
						<Col xs={9} className=''>{RecruitDetailInfo.DrivingStyle}</Col>
					</Row>
					<Row>
						<Col xs={3} className=''>고객이 싫어하는 것</Col>
						<Col xs={9} className=''>{RecruitDetailInfo.HateInfo}</Col>
					</Row>
					<Row>

						<Col xs={3} className=''>등급별 정산 내용</Col>
						<Col xs={9} className=''>
							<TextWithNewlines
								text={RecruitDetailInfo.PaymentDetailComment} />
						</Col>
					</Row>

					{(RecruitDetailInfo.RequestList.length >= 1) && (
						<>
							<Row>
								<Col xs={12} className='h4'>지원자 리스트</Col>
							</Row>
							<Table striped bordered hover size="sm">
								<thead>
									<tr>
										<th>고유 Id</th>
										<th>파트너코드</th>
										<th>이름</th>
										<th>전화번호</th>
										<th>상태</th>
										<th>요청일시</th>
									</tr>
								</thead>
								<tbody>
									{RecruitDetailInfo.RequestList?.map((data) => (
										<tr>
											<td>{data.AnnounceId}</td>
											<td>{data.DriverNo}</td>
											<td>{data.UserName}</td>
											<td>{data.PhoneNumber}</td>
											<td>{data.AnnounceStatus}</td>
											<td>{moment(data.RequestDt).format('yyyy-MM-DD HH:mm')}</td>
										</tr>
									))}
								</tbody>
							</Table>
						</>
					)}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						닫기
					</Button>
					{/* <Button variant="primary">Understood</Button> */}
					<Button variant="success" onClick={() => { window.open('http://mosiler.com/ojt/' + RecruitDetailInfo.OjtId) }}>수정</Button>
				</Modal.Footer>
			</Modal>

			<Modal
				show={modalFlagInsert}
				size="lg"
				onHide={handleClose2}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>공고 OJT 등록</Modal.Title>
				</Modal.Header>

				<Form onSubmit={handleSubmit(onSubmit)}>
					<Modal.Body>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>상품</Col>
							<Col xs={9}>
								<Form.Select {...register('product')} aria-label="Default select example">
									<option value="0">Open this select menu</option>
									<option value="1">월간모시러</option>
									<option value="2">연간모시러</option>
								</Form.Select>
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>상품 최소이용시간</Col>
							<Col xs={9}>
								<Form.Control type="text" placeholder="최소이용시간" {...register('minUsageTime')} />
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>상품 일</Col>
							<Col xs={9}>
								<Form.Control type="text" placeholder="상품 일" {...register('productDay')} />
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>상품 단가</Col>
							<Col xs={9}>
								<Form.Control type="text" placeholder="상품 단가" {...register('productPrice')} />
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>고객 이름</Col>
							<Col xs={9}>
								<Form.Control type="text" placeholder="고객 이름" {...register('customerName')} />
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>전화번호</Col>
							<Col xs={9}>
								<Form.Control type="text" placeholder="전화번호" {...register('phoneNumber')} />
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>이메일주소</Col>
							<Col xs={9}>
								<Form.Control type="text" placeholder="이메일주소" {...register('emailAddress')} />
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>차량 모델명</Col>
							<Col xs={9}>
								<Form.Control type="text" placeholder="벤츠 E580" {...register('carModel')} />
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>선호연령</Col>
							<Col xs={9}>
								<Form.Select {...register('preferredAge')} aria-label="Default select example">
									<option value="0">Open this select menu</option>
									<option value="1">30대</option>
									<option value="2">40대</option>
									<option value="3">50대</option>
									<option value="4">상관없음</option>
								</Form.Select>
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>자택주소</Col>
							<Col xs={9}>
								<Form.Control type="text" placeholder="서울 동대문구 ..." {...register('homeAddress')} />
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>회사주소</Col>
							<Col xs={9}>
								<Form.Control type="text" placeholder="서울 강남구 ..." {...register('companyAddress')} />
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>주차 가능여부</Col>
							<Col xs={9}>
								<Form.Select {...register('parkingAvailable')} aria-label="Default select example">
									<option value="0">Open this select menu</option>
									<option value="1">불가</option>
									<option value="2">가능</option>
								</Form.Select>
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>호칭</Col>
							<Col xs={9}>
								<Form.Control type="text" placeholder="서울 강남구 ..." {...register('title')} />
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>흡연 가능여부</Col>
							<Col xs={9}>
								<Form.Select {...register('smokingAllowed')} aria-label="Default select example">
									<option value="0">Open this select menu</option>
									<option value="1">금연</option>
									<option value="2">흡연</option>
									<option value="3">전자담배</option>
								</Form.Select>
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>키수령</Col>
							<Col xs={9}>
								<Form.Control type="text" placeholder="서울 강남구 ..." {...register('keyReception')} />
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>만나는 장소</Col>
							<Col xs={9}>
								<Form.Control type="text" placeholder="서울 강남구 ..." {...register('meetingPlace')} />
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>선호 운행스타일</Col>
							<Col xs={9}>
								<Form.Select {...register('preferredDrivingStyle')} aria-label="Default select example">
									<option value="0">Open this select menu</option>
									<option value="1">최대한 안전하게</option>
									<option value="2">최대한 신속하게</option>
									<option value="3">교통 상황에 맞춰서 센스있게</option>
									<option value="4">상황에 맞춰 직접 말을 하겠다.</option>
									<option value="5">기타</option>
								</Form.Select>
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>싫어하는 것</Col>
							<Col xs={9}>
								<Form.Select {...register('dislikes')} aria-label="Default select example">
									<option value="0">Open this select menu</option>
									<option value="1">흡연</option>
									<option value="2">과도한 향수 또는 섬유유연제</option>
									<option value="3">과도한 안전운전</option>
									<option value="4">선제적 말걸기</option>
									<option value="5">정돈되지 않은 외형</option>
									<option value="6">기타</option>
								</Form.Select>
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>이용예정요일</Col>
							<Col xs={9}>
								<div>
									<Form.Check inline label="월" {...register('daysOfUse')} value="월" type={'checkbox'} id={`inline-checkbox-1`} />
									<Form.Check inline label="화" {...register('daysOfUse')} value="화" type={'checkbox'} id={`inline-checkbox-2`} />
									<Form.Check inline label="수" {...register('daysOfUse')} value="수" type={'checkbox'} id={`inline-checkbox-3`} />
									<Form.Check inline label="목" {...register('daysOfUse')} value="목" type={'checkbox'} id={`inline-checkbox-4`} />
									<Form.Check inline label="금" {...register('daysOfUse')} value="금" type={'checkbox'} id={`inline-checkbox-5`} />
									<Form.Check inline label="토" {...register('daysOfUse')} value="토" type={'checkbox'} id={`inline-checkbox-6`} />
									<Form.Check inline label="일" {...register('daysOfUse')} value="일" type={'checkbox'} id={`inline-checkbox-7`} />
								</div>
							</Col>
						</Row>
						<Row>
							<Col xs={3} className="text-center" style={{ alignSelf: 'center' }}>별도 요청사항</Col>
							<Col xs={9}>
								<Form.Control type="text" placeholder="서울 강남구 ..." {...register('additionalRequests')} />
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose2}>
							닫기
						</Button>
						<Button variant="primary" type="submit">
							저장
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>

	);
};

export default RecruitList;

// 