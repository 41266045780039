import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Table } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { delTrainingManage, getAvailableTemplete, getTrainingManage, setTrainingManage } from '../../lib/RestApi';


const TrainingCenterManageView = (props) => {

	const [selectedDate, setSelectedDate] = useState()
	const [selectedDate2, setSelectedDate2] = useState()
	const [VenueSelect, setVenueSelect] = useState('0')
	const [TimeSelect, setTimeSelect] = useState('0')
	const [TrainingManageList, setTrainingManageList] = useState([])
	const [trainerDriverNo, setTrainerDriverNo] = useState('0')

	const [VenueList, setVenueList] = useState([])
	const [TimeList, setTimeList] = useState([])
	const [updateFlag, SetUpdateFlag] = useState(0)

	useEffect(() => {
		const getData = async () => {
			let result = await getAvailableTemplete()
			if (result.ResultCode  == 200) {
				setVenueList(result.ResultData.VenueList)
				setTimeList(result.ResultData.TimeList)
			}
			let result2 = await getTrainingManage()
			if (result2.ResultCode  == 200) {
				setTrainingManageList(result2.ResultData)
			}


		}
		getData();
	}, [updateFlag])

	const setData = async () => {
		// Validation Check
		if (VenueSelect == 0) {
			alert('교육장 템플릿을 선택해주세요')
			return;
		}
		if (TimeSelect == 0) {
			alert('시간 템플릿을 선택해주세요')
			return;
		}
		if (!selectedDate) {
			alert('정책 적용 시작시간을 선택해주세요.')
			return;
		}
		if (!selectedDate2) {
			alert('정책 적용 종료시간을 선택해주세요.')
			return;
		}
		if (!trainerDriverNo.length || trainerDriverNo == 0) {
			alert('파트너 코드를 입력해주세요.')
			return;
		}

		alert('교관 파트너 선택 부분 고려해야함. 대응 끝나면 본 alert 없앨것')

		let obj = {
			TrainingVenueTimeTempleteId: TimeSelect,
			TrainingVenueId: VenueSelect,
			StartDate: moment(selectedDate).format('yyyy-MM-DD'),
			EndDate: moment(selectedDate2).format('yyyy-MM-DD'),
			DriverNo: trainerDriverNo
		}
		try {
			if (window.confirm('등록 하시겠습니까?')) {
				let result = await setTrainingManage(obj)
				if (result.ResultCode == 200) {
					alert('설정완료 되었습니다.')
					SetUpdateFlag(prev=>(prev+1))
					setSelectedDate('')
					setSelectedDate2('')
					setVenueSelect('0')
					setTimeSelect('0')
				}
				else {
					alert('설정 실패 했습니다. 다시 확인해주세요.')
				}
			}
			
		}
		catch(e) {
			alert('설정 도중 문제가 발생했습니다. 다시 확인해주세요.')
		}

	}

	const delData = async (Id) => {
		try {
			if (window.confirm('삭제 하시겠습니까?')) {
				let result = await delTrainingManage(Id)
				if (result.ResultCode == 200) {
					alert('삭제 완료 되었습니다.')
					SetUpdateFlag(prev=>(prev+1))
					setSelectedDate('')
					setSelectedDate2('')
					setVenueSelect('0')
					setTimeSelect('0')
				}
				else {
					alert('삭제 실패 했습니다. 다시 확인해주세요.')
				}
			}
			
		}
		catch(e) {
			alert('삭제 도중 문제가 발생했습니다. 다시 확인해주세요.')
		}
	}

	const componentOptionList = (list) => {
		return list?.map((obj) => (
			<option value={obj.Id}>{obj.Label}</option>
		))
	}


	return (
		<>
			<Container fluid="md">
				<Row>
					<Col>
						<h2>교육장 스케줄 관리</h2>
						<b>(이미 추가한 교육장, 시간 템플릿은 선택 불가)</b>
					</Col>
				</Row>
				<br />
				<Row>
					<Col xs={3}>
						<h6>교육장 템플릿 선택</h6>
					</Col>
					<Col xs={3}>
						<h6>교육장 시간 템플릿 선택</h6>
					</Col>
					<Col xs={2}>
						<h6>정책 적용 시작시간</h6>
					</Col>
					<Col xs={2}>
						<h6>정책 적용 종료시간</h6>
					</Col>
					<Col xs={2}>
						<h6>교관 파트너 코드</h6>
					</Col>
				</Row>
				<Row>
					<Col xs={3}>
						<Form.Select aria-label="Default select example" value={VenueSelect} onChange={(e) => setVenueSelect(e.target.value)}>
							<option value='0'>--선택--</option>
							{componentOptionList(VenueList)}
						</Form.Select>
					</Col>
					<Col xs={3}>
						<Form.Select aria-label="Default select example" value={TimeSelect} onChange={(e) => setTimeSelect(e.target.value)}>
							<option value='0'>--선택--</option>
							{componentOptionList(TimeList)}
						</Form.Select>
					</Col>
					<Col xs={2}>
						<DatePicker
							selected={selectedDate}
							onChange={setSelectedDate}
							dateFormat="yyyy/MM/dd"
							className="form-control"
							placeholderText="Select a date"
						/>
					</Col>
					<Col xs={2}>
						<DatePicker
							selected={selectedDate2}
							onChange={setSelectedDate2}
							dateFormat="yyyy/MM/dd"
							className="form-control"
							placeholderText="Select a date"
						/>
					</Col>
					<Col xs={2}>
						<Form.Control type="text" value={trainerDriverNo} onChange={(e)=>setTrainerDriverNo(e.target.value)} placeholder="파트너코드 입력" />

						
					</Col>
				</Row>
				<br />
				<Button variant="primary" onClick={() => { setData() }}>등록</Button>{' '}
			</Container>
			<br />

			<Container fluid="md">
				<h2>교육장별 등록된 스케줄 리스트</h2>
				<Table striped bordered hover size="sm">
					<thead>
						<tr>
							<th>고유 Id</th>
							<th>교육장명</th>
							<th>타임, 인원수</th>
							<th>정책 적용 시작시간</th>
							<th>정책 적용 종료시간</th>
							<th>교관코드</th>
							<th>삭제</th>
						</tr>
					</thead>
					<tbody>
						{TrainingManageList.map((data, idx) => (
							<tr>
								<td>{data.TrainingScheduleId}</td>
								<td>{data.TrainingVenueName}</td>
								<td>{data.TimeInfo}</td>
								<td>{data.StartDate}</td>
								<td>{data.EndDate}</td>
								<td>{data.DriverNo}</td>
								<td>
									<Button variant="danger" onClick={() => { delData(data.TrainingScheduleId) }}>삭제</Button>{' '}
								</td>
							</tr>
						))}

					</tbody>
				</Table>
			</Container>
		</>

	);
};

export default TrainingCenterManageView;
