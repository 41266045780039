import React, { useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { URLS } from '../lib/urls';

const Home = () => {
    
    if (
        document.location.pathname.includes('/training/driver-schedule') ||
        document.location.pathname.includes('/chat/customer/detail') ||
        document.location.pathname.includes('/chat/patner/detail')
        

    )
        return (<></>)
    return (
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="#home">모시러 관리시스템(임시)</Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {/* <Nav.Link href="#home">Home</Nav.Link> */}
                        <NavDropdown title="교육(현시점미사용)" id="basic-nav-dropdown">
                            <NavDropdown.Item href={`${URLS.TRAINING_VENUE_TEMPLETE}`}>
                                교육장 템플릿
                            </NavDropdown.Item>
                            <NavDropdown.Item href={`${URLS.TRAINING_TIME_TEMPLETE}`}>
                                시간 템플릿
                            </NavDropdown.Item>
                            <NavDropdown.Item href={`${URLS.TRAINING_VENUE_MANAGE}`}>
                                교육장 스케줄 관리
                            </NavDropdown.Item>
                            <NavDropdown.Item href={`${URLS.TRAINING_DRIVER_REQUEST_LIST}`}>
                                교육 신청자 리스트
                            </NavDropdown.Item>
                            <NavDropdown.Item href={`${URLS.TRAINING_MANAGE_NEW}`}>
                                NEW 관리테이블
                            </NavDropdown.Item>


                            
                            {/* <NavDropdown.Divider /> */}
                        </NavDropdown>
                        <NavDropdown title="공고" id="basic-nav-dropdown">
                            <NavDropdown.Item href={`${URLS.RECRUIT_LIST}`}>리스트</NavDropdown.Item>
                        </NavDropdown>
                        <NavDropdown title="채팅" id="basic-nav-dropdown">
                            <NavDropdown.Item href={`${URLS.CHAT_PARTNER_LIST}`}>파트너</NavDropdown.Item>
                            <NavDropdown.Item href={`${URLS.CHAT_CUSTOMER_LIST}`}>고객</NavDropdown.Item>
                            
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}
export default Home;