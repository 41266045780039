import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Table } from 'react-bootstrap';
import { delVenueTemplete, getVenueTemplete, setVenueTemplete } from '../../lib/RestApi';

const VenueTempleteView = (props) => {

	const [TrainingVenueList, SetTraniningVenueList] = useState([])
	const [TrainingVenueName, SetTrainingVenueName] = useState('')
	const [TrainingVenueAddress, SetTrainingVenueAddress] = useState('')
	const [updateFlag, SetUpdateFlag] = useState(0)

	useEffect(()=>{
		const getData = async () => {
			let result = await getVenueTemplete()
			if (result.ResultCode == 200) {
				SetTraniningVenueList(result.ResultData)
			}
			else {
				alert(result.ResultMessage)
			}
		}
		getData();
	}, [updateFlag])

	const setData = async () => {
		try {
			if (TrainingVenueName.length <= 0) {
				alert('교육장 이름을 입력해주세요.')
				return;
			}
			if (TrainingVenueAddress.length <= 0) {
				alert('교육장 주소를 입력해주세요.')
				return;
			}

			if (window.confirm('추가 하겠습니까?')) {
				let result = await setVenueTemplete (TrainingVenueName, TrainingVenueAddress)
				if (result.ResultCode  == 200) {
					alert('설정완료 되었습니다.')
					SetUpdateFlag(prev=>(prev+1))
					SetTrainingVenueName('')
					SetTrainingVenueAddress('')
				}
				else {
					alert('설정에 실패 했습니다.')
				}
			}
		}
		catch(e) {

		}
	}

	const delData = async (Id) => {
		try {
			if (window.confirm('삭제 하겠습니까?')) {
				let result = await delVenueTemplete (Id)
				if (result.ResultCode  == 200) {
					alert('삭제완료 되었습니다.')
					SetUpdateFlag(prev=>(prev+1))
					SetTrainingVenueName('')
					SetTrainingVenueAddress('')
				}
				else {
					alert('삭제에 실패 했습니다.')
				}
			}
		}
		catch(e) {

		}
	}

	return (
		<>
			<Container fluid="md">
				<Row>
					<Col>
						<h2>교육장 템플릿 추가</h2>
					</Col>
				</Row>
				<Row>
					<Col xs={2}>
						<b>교육장 이름</b>
					</Col>
					<Col xs={10}>
						<Form.Control type="text" placeholder="교육장 이름 입력" value={TrainingVenueName} onChange={(e)=>SetTrainingVenueName(e.target.value)  } />
					</Col>
				</Row>
				<Row>
					<Col>
						<b>교육장 주소</b>
					</Col>
					<Col xs={10}>
						<Form.Control type="text" placeholder="교육장 주소 입력" value={TrainingVenueAddress} onChange={(e)=>SetTrainingVenueAddress(e.target.value)  } />
					</Col>
				</Row>
				<Row>
					<Col>
						<Button variant="primary" onClick={setData}>추가하기</Button>
					</Col>
				</Row>
			</Container>
			<br/>
			
			<Container fluid="md">
				<h2>교육장 템플릿 리스트</h2>	
				<Table striped bordered hover size="sm">
					<thead>
						<tr>
							<th>Id</th>
							<th>교육장 이름</th>
							<th>교육장 주소</th>
							<th>교육장 삭제</th>
						</tr>
					</thead>
					<tbody>
						{TrainingVenueList.map((data, idx)=>(
							<tr>
								<td>{data.TrainingVenueId}</td>
								<td>{data.TrainingVenueName}</td>
								<td>{data.TrainingVenueAddress}</td>
								<td>
									<Button variant="danger" onClick={()=>delData(data.TrainingVenueId)}>삭제하기</Button>
								</td>
							</tr>
						))}
						
					</tbody>
				</Table>
			</Container>
		</>

	);
};

export default VenueTempleteView;
