import React, { useEffect, useState } from 'react';
import { Container, ListGroup, Badge } from 'react-bootstrap';
import useInterval from '../../hooks/useInterval';
import { getChatList, updateChatReaded } from '../../lib/RestApi';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { URLS } from '../../lib/urls';
import { isNullOrUndefined } from '../../lib/Util';

const ChatPartnerList = () => {
    const [chatRooms, setChatRooms] = useState([])
    const [updateFlag, setUpdateFlag] = useState(0)
    const navigate = useNavigate()

    useEffect(() => {
        const getData = async () => {
            const result = await getChatList()
            setChatRooms(result?.ResultData)
        }
        getData();
    }, [updateFlag])

    useInterval(async () => {
        setUpdateFlag(updateFlag + 1)
    }, 3000)

    const truncateText = (text, maxLength) => {
        return text.length > maxLength ? text.substring(0, maxLength) + '...' : text;
    };

    const detailView = async (DriverNo, RoomId, Name, PhoneNumber) => {
        await updateChatReaded(RoomId)
        window.open(window.location.origin + `${URLS.CHAT_PARTNER_DETAIL}/${DriverNo}?name=${Name}&phone=${PhoneNumber}`, '_blank', 'toolbar=0,location=0,menubar=0,width=500,height=900');
    }

    const jsonConvert = (jsonStr) => {
        let retData = ''
        try {
            const result = JSON.parse(jsonStr)
            retData = result.Body
        }
        catch(e) {
            retData='오류발생'
        }
        return retData
    }

    const getStrRookieStatus = (data) => {
        // null이 아닐경우
        if (!isNullOrUndefined(data)) {
            // 신규가 아닐경우
            if (Number(data) >= 5) {
                return ''
            }
            //신규일경우
            else {
                return '[신규]'
            }
        }
        else {
            return '[신규]'
        }
    }

    return (
        <Container>
            <h3 className="my-4">파트너 채팅 목록</h3>
            <ListGroup>
                {chatRooms.map((room) => (
                    <ListGroup.Item key={room?.RoomId} className="d-flex justify-content-between align-items-start" onClick={()=>detailView(room?.DriverNo, room?.RoomId, room?.UserName, room?.PhoneNumber  )}>
                        <div className="ms-2 me-auto">
                            <div className="fw-bold">
                                {room?.UserName} 파트너 ({room?.PhoneNumber}) [{room?.DriverStatus}] {getStrRookieStatus(room?.DrivingCount)}
                            </div>
                            <div className="text-muted">{truncateText(jsonConvert(room?.Content) , 30)}</div>
                            <small>{moment(room?.RegDt).format('yy/MM/DD HH:mm')}</small>
                        </div>
                        {room?.UnreadCount > 0 && (
                            <Badge bg="primary" pill>
                                {room?.UnreadCount}
                            </Badge>
                        )}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </Container>
    );
};

export default ChatPartnerList;