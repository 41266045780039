import React, { useEffect } from 'react';
import { Route, Switch, Routes } from 'react-router-dom';

import { BUILD_TARGET_URI } from './InitConst';
import './css/init.css'
import './css/common.css'
import 'react-calendar/dist/Calendar.css';


import VenueTempleteView from './pages/page/VenueTempleteView';
import TimeTempleteView from './pages/page/TimeTempleteView';
import TrainingCenterManageView from './pages/page/TrainingCenterManageView';
import TrainingRequestListView from './pages/page/TrainingRequestListView';
import TrainingTrainerScheduleView from './pages/page/TrainingTrainerScheduleView';
import RecruitBasicView from './pages/page/RecruitBasicView';
import RecruitList from './pages/page/RecruitList';
import Home from './pages/Home';
import PageLayout from './pages/PageLayout';
import { URLS } from './lib/urls';
import ChatPartnerDetail from './pages/page/ChatPartnerDetail';
import ChatPartnerList from './pages/page/ChatPartnerList';
import NewTrainingTrainerScheduleView from './pages/page/NewTrainingTrainerScheduleView';
import NewTrainingTrainerScheduleViewTeacher from './pages/page/NewTrainingTrainerScheduleViewTeacher';
import ChatCustomerList from './pages/page/ChatCustomerList';
import ChatCustomerDetail from './pages/page/ChatCustomerDetail';
// import './css/floatlabel.css'
// import "react-datepicker/dist/react-datepicker.css";

/*
홍대 프로젝트 진행중
- 자동화페이지 (부커, )=> 피드백 => 확인 => 
- 고객앱 - 기획
  => 메신저, 사람..\ 예약(일반,), 이력확인
  => 관제, VOC, 쿠폰, 
- 보완
- 사전 교육자료. 
*/



function App() {
    return (
        <>
            <Home />
            <Routes>
                <Route element={<PageLayout />}>
                    <Route path={`${URLS.TRAINING_VENUE_TEMPLETE}`} element={<VenueTempleteView />} />
                    <Route path={`${URLS.TRAINING_TIME_TEMPLETE}`} element={<TimeTempleteView />} />
                    <Route path={`${URLS.TRAINING_VENUE_MANAGE}`} element={<TrainingCenterManageView />} />
                    <Route path={`${URLS.TRAINING_DRIVER_REQUEST_LIST}`} element={<TrainingRequestListView />} />
                    {/* <Route path={`${URLS.TRAINING_DRIVER_SCHEDULE}/:DateYm/:DriverNo`} element={<TrainingTrainerScheduleView />} /> */}
                    <Route path={`${URLS.TRAINING_DRIVER_SCHEDULE}/:venue`} element={<NewTrainingTrainerScheduleViewTeacher />} />
                    {/* /training/driver-schedule/홍대 */}
                    <Route path={`${URLS.TRAINING_MANAGE_NEW}`} element={<NewTrainingTrainerScheduleView />} />

                    

                    
                    
                    {/*  */}

                    <Route exact path={`${URLS.RECRUIT_LIST}`} element={<RecruitList />} />

                    <Route exact path={`${URLS.CHAT_PARTNER_LIST}`} element={<ChatPartnerList />} />
                    <Route exact path={`${URLS.CHAT_PARTNER_DETAIL}/:id`} element={<ChatPartnerDetail />} />

                    <Route exact path={`${URLS.CHAT_CUSTOMER_LIST}`} element={<ChatCustomerList />} />
                    <Route exact path={`${URLS.CHAT_CUSTOMER_DETAIL}/:id`} element={<ChatCustomerDetail />} />
                    
                    

                </Route>


            </Routes>
        </>

        // <Switch>
        //     {/* <Route exact path={`${BUILD_TARGET}/introduce`} component={IntroduceView} /> */}
        //     
        //     <Route exact path={`${BUILD_TARGET}/training/2`} component={TimeTempleteView} />
        //     <Route exact path={`${BUILD_TARGET}/training/3`} component={TrainingCenterManageView} />
        //     <Route exact path={`${BUILD_TARGET}/training/4`} component={TrainingRequestListView} />
        //     <Route exact path={`${BUILD_TARGET}/training/5/:DateYm/:DriverNo`} component={TrainingTrainerScheduleView} />


        // </Switch>
    );
}

export default App;
