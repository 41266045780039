import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Table } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment';
import { delTimeTemplete, getTimeTemplete, setTimeTemplete } from '../../lib/RestApi';

const TimeTempleteView = (props) => {

	const [UuidData, SetUuidData] = useState(uuidv4())
	const [TrainingTimeList, SetTraniningTimeList] = useState([])
	const [timeTemp, setTimeTemp] = useState([{ localUuid: uuidv4(), TrainingVenueTime: '', CapacitiesLimitCount:0 }])
	const [updateFlag, SetUpdateFlag] = useState(0)

	const increaseTemp = () => {
		setTimeTemp((prev) => ([
			...prev,
			{ localUuid: uuidv4(), TrainingVenueTime: '', CapacitiesLimitCount:0 }
		]))
	}

	const decreaseTemp = (localUuid) => {
		setTimeTemp((prev) => prev.filter((data) => localUuid !== data.localUuid));
	};


	useEffect(() => {
		const getData = async () => {
			let result = await getTimeTemplete()
			if (result.ResultCode == 200) {
				SetTraniningTimeList(result.ResultData)
			}
			else {
				alert(result.ResultMessage)
			}
		}
		getData();
	}, [updateFlag])

	const setData = async () => {
		try {
			let SetDataArr = []
			timeTemp.forEach((data)=>{
				SetDataArr.push({
					TrainingVenueTimeTempleteId: UuidData,
					TrainingVenueTime: data.TrainingVenueTime,
					CapacitiesLimitCount: data.CapacitiesLimitCount
				})
			})

			if (window.confirm('추가 하겠습니까?')) {
				let result = await setTimeTemplete(SetDataArr)
				if (result.ResultCode  == 200) {
					alert('설정완료 되었습니다.')
					SetUpdateFlag(prev=>(prev+1))
					setTimeTemp([{ localUuid: uuidv4(), TrainingVenueTime: '', CapacitiesLimitCount:0 }])
					SetUuidData(uuidv4())
				}
				else {
					alert('설정에 실패 했습니다.')
				}
			}
		}
		catch(e) {

		}
	}

	const delData = async (id) => {
		try {
			
			if (window.confirm('삭제 하겠습니까?\n(같은 템플릿 ID 모두 삭제됩니다.)')) {
				let result = await delTimeTemplete(id)
				if (result.ResultCode  == 200) {
					alert('삭제완료 되었습니다.')
					SetUpdateFlag(prev=>(prev+1))
					setTimeTemp([{ localUuid: uuidv4(), TrainingVenueTime: '', CapacitiesLimitCount:0 }])
					SetUuidData(uuidv4())
				}
				else {
					alert('설정에 실패 했습니다.')
				}
			}
		}
		catch(e) {

		}
	}


	return (
		<>
			<Container fluid="md">
				<Row>
					<Col>
						<h2>시간 템플릿 추가</h2>
					</Col>
				</Row>

				<Row>
					<Col xs={2}>
						<b>템플릿 고유아이디</b>
					</Col>
					<Col xs={10}>
						<Form.Control type="text" placeholder="교육장 이름 입력" value={UuidData} disabled />
					</Col>

				</Row>

				{timeTemp.map((data, idx) => (
					<>
						<Row>
							<Col xs={2}>
								<b>시간</b>
							</Col>
							<Col xs={9}>
								<Form.Control
									type="text"
									id={data.localUuid}
									value={data.TrainingVenueTime}
									onChange={(e) => {
										const newTime = e.target.value;
										setTimeTemp((prev) => prev.map(item =>
											item.localUuid === data.localUuid ? { ...item, TrainingVenueTime: newTime } : item
										));
									}}
								/>
							</Col>
							<Col xs={1}>
								
							</Col>
						</Row>
						<Row>
							<Col xs={2}>
								<b>인원수</b>
							</Col>
							<Col xs={9}>
								<Form.Control
									type="text"
									id={data.localUuid}
									value={data.CapacitiesLimitCount}
									onChange={(e) => {
										const newTime = e.target.value;
										setTimeTemp((prev) => prev.map(item =>
											item.localUuid === data.localUuid ? { ...item, CapacitiesLimitCount: newTime } : item
										));
									}}
								/>
							</Col>
							<Col xs={1}>
								<Button variant="danger" id={data.localUuid} onClick={() => { decreaseTemp(data.localUuid) }}>삭제</Button>
							</Col>
						</Row>
					</>
					
					
				)

				)}



				<Row>
					<Col>
						<Button variant="primary" onClick={() => { increaseTemp() }}>추가</Button>{' '}
						<Button variant="primary" onClick={() => { setData() }}>최종저장하기</Button>{' '}
					</Col>
				</Row>
			</Container>
			<br />

			<Container fluid="md">
				<h2>시간 템플릿 리스트</h2>
				<Table striped bordered hover size="sm">
					<thead>
						<tr>
							<th>고유 Id</th>
							<th>템플릿 Id</th>
							<th>시간</th>
							<th>인원수</th>
							<th>삭제</th>
						</tr>
					</thead>
					<tbody>
						{TrainingTimeList.map((data, idx) => (
							<tr>
								<td>{data.TrainingVenueCapacitiesId}</td>
								<td>{data.TrainingVenueTimeTempleteId}</td>
								<td>{moment(data.TrainingVenueTime).format('HH:mm')}</td>
								<td>{data.CapacitiesLimitCount}</td>
								<td>
									<Button variant="primary" onClick={() => { delData(data.TrainingVenueTimeTempleteId) }}>삭제</Button>{' '}
									</td>
							</tr>
						))}

					</tbody>
				</Table>
			</Container>
		</>

	);
};

export default TimeTempleteView;
