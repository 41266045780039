

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
//import IntroduceView from './pages/page/IntroduceView';
import App from './App';
import {hydrate, render} from 'react-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';



const Root = () => {  
    // const rootElement = document.getElementById("root");
    // if (rootElement.hasChildNodes()) {
    //     hydrate(<App />, rootElement)
    // }
    // else {
    //     render(<App />, rootElement)
    // }
    return (
        <BrowserRouter>
            <CookiesProvider>
            <App />
            </CookiesProvider>
        </BrowserRouter>
    );
};

export default Root;