import React from 'react';
import { Outlet } from 'react-router-dom';

const PageLayout = () => {
  return (
    <>
      <main className={'page-layout'}>
        <Outlet />
      </main>
    </>
  );
};

export default PageLayout;
