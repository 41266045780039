import CryptoJS from 'crypto-js'
import axios from 'axios';
import qs from 'qs'
import moment from 'moment'

const SERVER_URL = 'https://api.admin.mosiler.com'
// const SERVER_URL = 'http://127.0.0.1:24315'
// const SERVER_URL = 'http://apitest.admin.mosiler.com'
// const SERVER_URL = 'http://localhost:3000'
const TIME_OUT = 30000

const key = 'mm'

export const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getLocalStorage = (id) => {
    return localStorage.getItem(id) !== null ? JSON.parse(localStorage.getItem(id)) : null;
}

export const setLocalStorage = (id, jsondata) => {
    localStorage.setItem(id, JSON.stringify(jsondata));
}



export const getQueryStringObject = () => {
    var a = window.location.search.substr(1).split('&');
    if (a === "") return {};
    var b = {};
    for (var i = 0; i < a.length; ++i) {
        var p = a[i].split('=', 2);
        if (p.length === 1)
            b[p[0]] = "";
        else
            b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, " "));
    }
    return b;
}

export const isChkUserInfo = (info1, info2) => {
    //info1 : qs로 들어온 데이터를 암호화한 데이터
    //info2 : ls데이터
    //true : qs쪽 데이터와 ls 데이터가 동일 | false : 동일하지 않음
    return (info1 === info2)
}

export const getUserInfoEncrypt = (info1) => {
    let saveData = JSON.stringify(info1)
    return window.btoa(unescape(encodeURIComponent(saveData)))
}

export const getLocalStorageDecrypt = (id) => {
    if (localStorage.getItem(id) !== null) {
        let decrypt = decodeURIComponent(escape(window.atob(localStorage.getItem(id))));
        // console.log(JSON.parse(decrypt))
        return JSON.parse(decrypt)
    }
    return null;
    
    //return localStorage.getItem(id) !== null ? JSON.parse( atob(localStorage.getItem(id)) ) : null;
}

export const setLocalStorageEncrypt = (id, jsondata) => {
    let saveData = JSON.stringify(jsondata)
    let convert = window.btoa(unescape(encodeURIComponent(saveData)))
    localStorage.setItem(id, convert) ;
}

export const getSessionStorageDecrypt = (id) => {
    if (sessionStorage.getItem(id) !== null) {
        let decrypt = decodeURIComponent(escape(window.atob(sessionStorage.getItem(id))));
        // console.log(JSON.parse(decrypt))
        return JSON.parse(decrypt)
    }
    return null;
    
    //return localStorage.getItem(id) !== null ? JSON.parse( atob(localStorage.getItem(id)) ) : null;
}

export const setSessionStorageEncrypt = (id, jsondata) => {
    let saveData = JSON.stringify(jsondata)
    let convert = window.btoa(unescape(encodeURIComponent(saveData)))
    sessionStorage.setItem(id, convert) ;
}

export const getEnglishDayToKorean = (daysName) => {
    let retData = ''
    switch(daysName) {
        case 'Mon':
            retData = '월'
            break;
        case 'Tue':
            retData = '화'
            break;
        case 'Wed':
            retData = '수'
            break;
        case 'Thu':
            retData = '목'
            break;
        case 'Fri':
            retData = '금'
            break;
        case 'Sat':
            retData = '토'
            break;
        case 'Sun':
            retData = '일'
            break;
        default:
            retData = '0'
            break;
    }
    return retData;
}


// Email Address Validation
export const isChkEmail = (data) => {
    let exptext = /^[A-Za-z0-9_\.\-]+@[A-Za-z0-9\-]+\.[A-Za-z0-9\-]+/;
    return exptext.test(data);
}

// String Validation
// length >= 1 : true | <= 0 : false
export const isChkString = (data) => {
    return data.length;
}

export const isChkPhone = (phone) => {
    return (/^[0-9]{3}[0-9]{3,4}[0-9]{4}/g.test(phone)) && (phone.length>=1);
}

export const isValidation = (type, data, msg) => {
    if (type === 'email') {
        if (!isChkEmail(data)) {
            alert(msg)
            return false;
        }
        return true;
    }
    else {
        if (!isChkString(data)) {
            alert(msg)
            return false;
        }
        return true;
    }
}

export const promotionDisCountMonth = (month) => {
    let retData = 0;
    if (Number(month) === 6) {
        retData = 5
    }
    else if (Number(month) === 12) {
        retData = 7
    }
    else {
        retData = 0;
    }
    return retData
}

export const isBusanFlag = () => {
    return (document.location.href.indexOf('busan') >= 0)
}

export const isValidKoreanPhoneNumber = (phoneNumber) => {
    var regex = /^01[0|1|6|7|8|9][0-9]{8}$/;
    return regex.test(phoneNumber);
}

export const EncryptAES = (message1) => {
    const key = CryptoJS.enc.Utf8.parse('mosilerisbestexi');
    const iv = CryptoJS.enc.Utf8.parse('exitexitexitexit');

    let encrypted1 = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(message1), key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

    return encrypted1.toString()
}

// 현재 날짜 기준으로 전년 12/18로 할지를 체크하는 함수이다.
// SIMG 에서 만나이를 정할때 12/18로 만나이를 정한다고 한다.
// 만약 현재 날짜가 2024년 12월 19일라면 기준 날짜는 2024/12/18가 된다
// 만약 현재 날짜가 2024년 01월 19일라면 기준 날짜는 2023/12/18가 된다
const GetInsuranceYearAgeData = () => {
    const currentDate = moment();
    const currentYear = moment(currentDate).year();
    const startOfYearPeriod = moment(`${currentYear}-12-18`);
    const endOfYearPeriod = moment(`${currentYear + 1}-12-17`);

    if (moment(currentDate).isBetween(startOfYearPeriod, endOfYearPeriod, undefined, '[]')) {
        return `${currentYear}-12-18`;
    } else {
        return `${currentYear - 1}-12-18`;
    }    
    
}


export const calculateAmount = (birthDateStr) => {
    // 오늘 날짜 정보를 가져온다
    const today = moment(GetInsuranceYearAgeData()).toDate();
    console.log(today)

    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1; // JavaScript에서 월은 0부터 시작하기 때문에 +1을 해준다
    const currentDay = today.getDate();

    // 생년월일을 가져온다
    const birthYear = Number(birthDateStr.substring(0, 2)) + 1900; // 년
    const birthMonth = Number(birthDateStr.substring(2, 4)); // 월
    const birthDay = Number(birthDateStr.substring(4, 6)); // 일

    // 만나이를 계산한다
    let age = currentYear - birthYear;
    console.log(age)
    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
        age--;
    }

    // 만나이에 따른 원을 반환한다
    // if (age >= 29 && age <= 49) {
    //     return 59100;
    // } else if (age >= 50 && age <= 55) {
    //     return 81650;
    // } else if (age >= 56 && age <= 60) {
    //     return 103010;
    // } else {
    //     return 0; // 해당 범위를 벗어난 경우 0을 반환한다
    // }
    if (age >= 29 && age <= 49) {
        return 53910;
    } else if (age >= 50 && age <= 55) {
        return 78800;
    } else if (age >= 56 && age <= 60) {
        return 102930;
    } else {
        return 0; // 해당 범위를 벗어난 경우 0을 반환한다
    }
}

export const PartnerLogin = (name, socialNum) => {
    let form = qs.stringify({
        'data': EncryptAES(JSON.stringify({
            UserName: name,
            SocialNumber: socialNum
        }))
    });

    return new Promise(async (resolve, reject)=>{
        const uri = `${SERVER_URL}/api/Driver/LoginDriverType3`
        axios({
            url : uri,
            method: 'POST',
            timeout: TIME_OUT,
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: form
        }).then( async res => {
            if (res.data.ResultCode === 'Success') {
                resolve(res.data)
            }
            else {
                if (res.data.ResultMessage === "데이터를 취득하지 못했습니다.") {
                    resolve({
                        ResultCode: 'Failed',
                        ResultMessage: '주민등록번호 오류\n(모시러 파트너 미가입자는 회원가입 후 보험 신청해 주시기 바랍니다.)',
                    })    
                }
                else {
                    resolve({
                        ResultCode: 'Failed',
                        ResultMessage: '알 수 없는 오류가 발생했습니다. 모시러에 문의해주세요.',
                    })    
                }
                
            }
            
            //res.data.result === 'success'
        }).catch( (err) => {
            resolve({
                ResultCode: 'Failed',
                ResultMessage: '로그인 과정에서 오류가 발생했습니다.',
            })    
        });

    })
}


export const SetPaymentCardDriver = (obj) => {
    let form = qs.stringify({
        'data': EncryptAES(JSON.stringify(obj))
    });

    return new Promise(async (resolve, reject)=>{
        const uri = `${SERVER_URL}/api/Driver/SetPaymentCardDriver`
        axios({
            url : uri,
            method: 'POST',
            timeout: TIME_OUT,
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: form
        }).then( async res => {
            resolve(res.data)
        }).catch( (err) => {
            resolve({
                ResultCode: 'Failed',
                ResultMessage: '카드 등록 과정에서 오류가 발생했습니다.',
            })    
        });

    })
}


export const DoPaymentDriver = (obj) => {
    let form = qs.stringify({
        'data': EncryptAES(JSON.stringify(obj))
    });

    return new Promise(async (resolve, reject)=>{
        const uri = `${SERVER_URL}/api/Driver/DoPaymentDriver`
        axios({
            url : uri,
            method: 'POST',
            timeout: TIME_OUT,
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: form
        }).then( async res => {
            resolve(res.data)
            
            //res.data.result === 'success'
        }).catch( (err) => {
            resolve({
                ResultCode: 'Failed',
                ResultMessage: '카드 결제 과정에서 오류가 발생했습니다.',
            })    
        });

    })
}


export const ChkRegistCard = (obj) => {
    let form = qs.stringify({
        'data': EncryptAES(JSON.stringify(obj))
    });

    return new Promise(async (resolve, reject)=>{
        const uri = `${SERVER_URL}/api/Driver/ChkRegistCard`
        axios({
            url : uri,
            method: 'POST',
            timeout: TIME_OUT,
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: form
        }).then( async res => {
            resolve(res.data)
        }).catch( (err) => {
            resolve({
                ResultCode: 'Failed',
                ResultMessage: '카드 등록 체크 과정에서 오류가 발생했습니다.',
            })    
        });

    })
}

export const ChkPurchaseAmountInsurancePerson = (obj) => {
    let form = qs.stringify({
        'data': EncryptAES(JSON.stringify(obj))
    });

    return new Promise(async (resolve, reject)=>{
        const uri = `${SERVER_URL}/api/Driver/ChkPurchaseAmountInsurancePerson`
        axios({
            url : uri,
            method: 'POST',
            timeout: TIME_OUT,
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: form
        }).then( async res => {
            resolve(res.data)
        }).catch( (err) => {
            resolve({
                ResultCode: 'Failed',
                ResultMessage: '카드 등록 체크 과정에서 오류가 발생했습니다.',
            })    
        });

    })
}

export const ChkInsurancePerson = (obj) => {
    let form = qs.stringify({
        'data': EncryptAES(JSON.stringify(obj))
    });

    return new Promise(async (resolve, reject)=>{
        const uri = `${SERVER_URL}/api/Driver/ChkInsurancePerson`
        axios({
            url : uri,
            method: 'POST',
            timeout: TIME_OUT,
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: form
        }).then( async res => {
            resolve(res.data)
        }).catch( (err) => {
            resolve({
                ResultCode: 'Failed',
                ResultMessage: '보험 심사 여부 체크 과정에서 오류가 발생했습니다.',
            })    
        });

    })
}

export const InsuranceRequestCall = (obj) => {
    let form = qs.stringify({
        'data': EncryptAES(JSON.stringify(obj))
    });

    return new Promise(async (resolve, reject)=>{
        const uri = `${SERVER_URL}/api/Driver/InsuranceRequestCall`
        axios({
            url : uri,
            method: 'POST',
            timeout: TIME_OUT,
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: form
        }).then( async res => {
            resolve(res.data)
        }).catch( (err) => {
            resolve({
                ResultCode: 'Failed',
                ResultMessage: '콜당 보험 등록 과정에서 오류가 발생했습니다.',
            })    
        });

    })
}

export const InsuranceRequestHyundai = (obj) => {
    let form = qs.stringify({
        'data': EncryptAES(JSON.stringify(obj))
    });
    
    return new Promise(async (resolve, reject)=>{
        const uri = `${SERVER_URL}/api/Driver/InsuranceRequestHyundai`
        axios({
            url : uri,
            method: 'POST',
            timeout: TIME_OUT,
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: form
        }).then( async res => {
            resolve(res.data)
        }).catch( (err) => {
            resolve({
                ResultCode: 'Failed',
                ResultMessage: '월간 보험 등록 과정에서 오류가 발생했습니다.',
            })    
        });

    })
}


export const PostKakaoDriverInsuranceComp = (name, phone) => {
    let form = qs.stringify({
        DriverName:name,
        Phone: phone
    });

    return new Promise(async (resolve, reject)=>{
        const uri = `${SERVER_URL}/api/Driver/PostKakaoDriverInsuranceComp`
        axios({
            url : uri,
            method: 'POST',
            timeout: TIME_OUT,
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: form
        }).then( async res => {
            if (res.data.ResultCode === 'Success') {
                resolve(res.data)
            }
            else {
                resolve({
                    ResultCode: 'Failed',
                    ResultMessage: '로그인 과정에서 통신 오류가 발생했습니다.',
                })    
            }
            
            //res.data.result === 'success'
        }).catch( (err) => {
            resolve({
                ResultCode: 'Failed',
                ResultMessage: '로그인 과정에서 오류가 발생했습니다.',
            })    
        });

    })
}

export const setSheetInsuranceInfo = (obj) => {
    const saveSheet = '랜딩신규'
    let form = qs.stringify({
        '파트너코드':        obj?.DriverNo,
        '파트너이름':        obj?.DriverName,
        '생년월일':          obj?.BirthDate,
        '파트너전화번호':    obj?.PhoneNumber,
        '통신사':           obj?.Provider,
        '명의자':           obj?.Provider,
        '주소':             obj?.Address,
        '보험료 금액':       obj?.Amount,
        '보험검수예약일':    obj?.ReserveInsuranceDate,
        'timestamp':        moment().format('yyyy-MM-DD HH:mm:ss'),
        
    });

    return new Promise((resolve, reject) => {
        axios({
            url: `https://script.google.com/macros/s/AKfycbw6hkj8AkcI39y1lSn8MHuSeDjJvMmjcDHk8NJQyV13p4mrZuV8ug9-0tzXw94Wz46Fgg/exec?savesheetname=${saveSheet}`,
            method: 'POST',
            timeout: 10000,
            data: form
        }).then(async res => {
            resolve(res.data.result)
            //res.data.result === 'success'
        }).catch((err) => {
            resolve('fail')
        });
    })
}

export const setSheetInsuranceInfoUpdate = (obj, userinfo) => {
    const saveSheet = '랜딩갱신PG'
    let form = qs.stringify({
        '파트너코드':        obj?.DriverNo,
        '파트너이름':        obj?.DriverName,
        '생년월일':          obj?.BirthDate,
        '파트너전화번호':    obj?.PhoneNumber,
        '보험료 금액':       obj?.Amount,
        'timestamp':        moment().format('yyyy-MM-DD HH:mm:ss'),       
    });

    return new Promise((resolve, reject) => {
        axios({
            url: `https://script.google.com/macros/s/AKfycbw6hkj8AkcI39y1lSn8MHuSeDjJvMmjcDHk8NJQyV13p4mrZuV8ug9-0tzXw94Wz46Fgg/exec?savesheetname=${saveSheet}`,
            method: 'POST',
            timeout: 10000,
            data: form
        }).then(async res => {
            resolve(res.data.result)
            //res.data.result === 'success'
        }).catch((err) => {
            resolve('fail')
        });
    })
}


export const getDaysInMonth = (year, month) => {
    const startOfMonth = moment(`${year}-${month}`, "YYYY-MM").startOf('month');
    const endOfMonth = moment(`${year}-${month}`, "YYYY-MM").endOf('month');
    const days = [];
  
    for (let date = startOfMonth; date.isBefore(endOfMonth) || date.isSame(endOfMonth); date.add(1, 'days')) {
        if (date.format('ddd') === 'Sat' || date.format('ddd') === 'Sun') continue;
        days.push({
            Date: date.format('YYYY-MM-DD'),
            DayKorean: getEnglishDayToKorean(date.format('ddd')),
            Status: 1,
        });
    }
  
    return days;
  }
  export const isNullOrUndefined = value => {
    return value === null || value === undefined;
  };

  
export const daysToHex = (days) => {
    const dayMapping = {
      '월': 1 << 0,
      '화': 1 << 1,
      '수': 1 << 2,
      '목': 1 << 3,
      '금': 1 << 4,
      '토': 1 << 5,
      '일': 1 << 6,
    };
    
    let bitmask = 0;
    days.split(',').forEach(day => {
      bitmask |= dayMapping[day];
    });
  
    return bitmask.toString(16); // 16진수 문자열로 변환
  }
  
  export const hexToDays = (bitmask) => {
    const daysArray = ['월', '화', '수', '목', '금', '토', '일'];
    const result = new Array(7).fill(false);
  
    for (let i = 0; i < daysArray.length; i++) {
      if ((bitmask & (1 << i)) !== 0) {
        result[i] = true;
      }
    }
    
    return result;
  }

  